<template>
  <div>
    <Header title="设备列表" color="#666" />
    <van-list
      class="pdb50 "
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
    >
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        center
        :to="{ path: '/device/detail', query: { id: item.id } }"
      >
        <template #title>
          <div class="flex-row fs14">
            <div class="flex-grow-1 fwb">站点名称：{{ item.siteName }}</div>
            <div class="flex-grow-0">
              <van-tag :type="getByStatus(item.netStatus)">{{
                item.netStatus
              }}</van-tag>
            </div>
          </div>
        </template>
        <template #label>
          <div class="flex-col mgt5 fs13">
            <div class="flex-grow-0 flex-row color666">
              <div class="flex-grow-1">设备号：{{ item.equipmentNum }}</div>
              <div class="flex-grow-0">设备类型：{{ item.equipmentType }}</div>
            </div>
            <div class="flex-grow-0 flex-row mgt5 color999">
              <div class="flex-grow-1">设备名称： {{ item.name }}</div>
              <div class="flex-grow-0 flex-xy-center time mgl10">
                {{ parseTime(item.createTime) }}
              </div>
            </div>
          </div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { getDeviceList } from "@/api/device";
import { formatParams } from "@/utils/params";
import { Cell, List, Button, Toast, Tag } from "vant";
import Header from "@/components/header";
export default {
  name: "DeviceIndex",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Tag.name]: Tag,
    Header
  },
  props: {},
  data() {
    return {
      loading: false,
      finished: false,
      listQuery: {
        pageNo: 1,
        limit: 10
      },
      list: []
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.parseTime = this.utils.parseTime;
  },
  methods: {
    getList() {
      this.loading = true;
      const params = formatParams(
        { "Q^NAME_^SL": "" },
        { page: this.listQuery.pageNo }
      );
      getDeviceList(params)
        .then(response => {
          this.loading = false;
          let list = this.list;
          let page = parseInt(this.listQuery.pageNo);
          if (this.listQuery.pageNo === 1) {
            list = response.data.dataResult;
          } else {
            list = list.concat(response.data.dataResult);
          }
          if (
            response.data.pageResult.page ===
              response.data.pageResult.totalPages ||
            response.data.pageResult.totalCount === 0
          ) {
            this.finished = true;
          }
          this.listQuery.pageNo = page + 1;
          this.list = list;
        })
        .catch(err => {
          Toast.fail(err);
        });
    },
    // 查询
    handleFilter() {
      this.listQuery.pageNo = 1;
      this.loading = true;
      this.finished = false;
      this.getList();
    },
    //根据类型获取状态
    getByStatus(status) {
      if (status == "离线") {
        status = 0;
      } else if (status == "在线") {
        status = 1;
      } else {
        status = 2;
      }
      const statusArr = ["danger", "success", "warning"];
      return statusArr[status];
    }
  }
};
</script>
<style lang="scss" scoped>
.van-cell{
  margin-bottom: 0.1rem;
  margin-top: 0 !important;
}

</style>
